import React from 'react';
import { Link } from 'gatsby';
import logo from '../img/stacked-logo2.png';

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: '',
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            });
      }
    );
  };

  render() {
    return (
      <nav
        className="navbar has-background-white navbar-border"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <img src={logo} alt="Organized Transitions" />
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start"></div>
            <div className="navbar-end">
              <Link className="navbar-item navbar-item-main" to="/">
                Home
              </Link>
              <div className="navbar-item has-dropdown is-hoverable">
                <Link
                  className="navbar-item navbar-item-main"
                  to="/moving-company"
                >
                  About Us
                </Link>

                <div className="navbar-dropdown">
                  <Link className="navbar-item" to="/testimonials">
                    Testimonials
                  </Link>
                </div>
              </div>
              <div className="navbar-item has-dropdown is-hoverable">
                <div className="navbar-item navbar-item-main">Services</div>

                <div className="navbar-dropdown">
                  <Link className="navbar-item" to="/services/move-management">
                    Downsizing / Move Management
                  </Link>
                  <Link className="navbar-item" to="/services/estate-clear-out">
                    Estate Clear Out
                  </Link>
                  <Link className="navbar-item" to="/services/decluttering">
                    Decluttering
                  </Link>
                  <Link className="navbar-item" to="/services/home-staging">
                    Home Staging
                  </Link>
                  <Link
                    className="navbar-item"
                    to="/services/auction-management"
                  >
                    Auction Management
                  </Link>
                </div>
              </div>
              <Link
                className="navbar-item navbar-item-main"
                to="/photo-gallery"
              >
                Gallery
              </Link>
              <Link className="navbar-item navbar-item-main" to="/contact">
                Contact
              </Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
