import React from 'react';

const ContactBar = () => {
  const emailConvert = () => {
    if (typeof window !== `undefined`) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-817271415/uGlDCP6CrN0BEPek2oUD',
      });
    }
  };

  const phoneConvert = () => {
    if (typeof window !== `undefined`) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-817271415/GFRGCOaCrN0BEPek2oUD',
      });
    }
  };

  return (
    <div className="contact-bar has-background-primary">
      <div className="container has-background-transparent">
        <nav className="level  has-text-white is-size-5 is-lowercase ">
          <div className="level-left"></div>
          <div className="level-right">
            <div className="level-item">
              <a
                href="tel:+19055991816"
                className="has-text-white"
                onClick={phoneConvert}
              >
                1 (905) 599-1816
              </a>
            </div>
            <div className="level-item">
              <a
                href="mailto:info@organizedtransitions.ca"
                className="has-text-white"
                onClick={emailConvert}
              >
                info@organizedtransitions.ca
              </a>
            </div>
            <div className="level-item contact-icon">
              <a
                href="https://www.facebook.com/OrganizedTransitionsMoveManagement/"
                target="_blank"
                className="has-text-white"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <i className="fab fa-facebook-f"></i>
                </span>
              </a>
            </div>
            <div className="level-item contact-icon">
              <a
                href="https://www.instagram.com/organized_transitions/"
                target="_blank"
                className="has-text-white"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <i className="fab fa-instagram"></i>
                </span>
              </a>
            </div>
            <div className="level-item contact-icon">
              <a
                href="https://www.google.com/maps/place/Organized+Transitions/@43.2894413,-80.2060276,10z/data=!3m1!4b1!4m5!3m4!1s0x882c85bfdc5408f1:0xc90343dce053f2df!8m2!3d43.2892834!4d-79.9258485"
                target="_blank"
                className="has-text-white"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <span className="mdi mdi-google-my-business mdi-18px"></span>
                </span>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default ContactBar;
