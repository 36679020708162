import React from 'react';
import { Link } from 'gatsby';

const Footer = class extends React.Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <footer className="footer has-background-black has-text-white">
        <div className="content has-text-centered">
          <p className="is-size-7">
            <Link to="/privacy-policy">Privacy Policy</Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Copyright ©{year} | Organized
            Transitions | All Rights Reserved
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Web Design by{' '}
            <a href="https://ninesixtygroup.com">Ninesixty Group</a>
          </p>
        </div>
      </footer>
    );
  }
};

export default Footer;
